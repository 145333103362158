import { collection, doc, getFirestore, orderBy, query } from "firebase/firestore";
import * as React from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { app } from "../firebase";
const FujiDemo = () => {

    const [value, loading, error] = useDocument(doc(getFirestore(app), 'fuji', 'device1'), {
        snapshotListenOptions: { includeMetadataChanges: true }
    });


    return <div className="container mx-auto mt-8">
        <h1 className="text-center text-4xl">Machine Running Demo</h1>
        <p>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Document: Loading...</span>}

        </p>
        {value &&
            <div className="flex max-w-1 flex-row gap-5 mx-auto my-10" style={{ width: '80%' }} >
                <div className="flex-col gap-5 w-full">
                    <div className="form-control my-2 flex-1 w-full">
                        <label className="input-group">
                            <span>Device Id</span>
                            <input type="text" disabled placeholder="Device10" value={value?.data1} className="input input-bordered" />
                        </label>
                    </div>
                    <div className="form-control my-2 flex-1 w-full">
                        <label className="input-group">
                            <span>Parameter 1</span>
                            <input type="text" disabled placeholder="Parameter 1" value={value?.data1} className="input input-bordered" />
                        </label>
                    </div>
                </div>

            </div>
        }

        <div>
            {value && <pre>{JSON.stringify(value.data(), null, 2)}</pre>}
        </div>
    </div>
}

export default FujiDemo

export const Head = () => <title>FourLink- Machine Running Demo</title>